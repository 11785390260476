
@charset "UTF-8";

.pg-company,
.pg-guide,
.pg-top,
.pg-flow {
	font-family: "Helvetica Neue", Helvetica, Arial, "游ゴシック体", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
	.container {
		//padding: 0;
	}
	.blc-h1 {
		background-color: #ff8c46;
		.text-h1 {
			padding: 10px 0 10px 10px;
			margin-top: 0;
		}
	}
	.blc-header-top {
		margin: 15px 0;
		@media screen and (max-width:767px) {
			margin: 10px 0;
		}
	}
	.blc-nav {
		margin-bottom: 0;
		li {
			font-weight: bold;
			@include font-size(16);
		}
		ul {
			li:before {
				content: "｜";
				display: inline-block;
				color: #FFF !important;
			}
			li:first-child:before {
				display: none;
			}
			@media screen and (max-width:767px) {
				li:before {
					display: none;
				}
			}
		}
		a {
			display: inline-block !important;
			color: #FFF !important;
		}
	}
	.blc-logo {
		padding-left: 0;
	}
	.blc-tel {
		text-align: right;
		height: 100%;
		padding-right: 0;
		img {
			display: inline-block;
			vertical-align: middle;
		}
	}
	.blc-tel:before {
	    content: "";
	    display: inline-block;
	    height: 100%;
	    vertical-align: middle;
	}
	.blc-main-copy {
		padding: 10px;
		background:-webkit-linear-gradient(#faaa46 50%, #ff8c46 50%, #ff8c46);
		background:-o-linear-gradient(#faaa46 50%, #ff8c46 50%, #ff8c46);
		background:linear-gradient(#faaa46 50%, #ff8c46 50%, #ff8c46);
		font-weight: bold;
		@include font-size(24);
		@media screen and (max-width:767px) {
			@include font-size(16);
		}
	}
	.text-shadow {
		margin: 0;
		color: #FFF;
		text-shadow:1px 1px 2px #666666;
		font-weight: bold;
	}
	.text-bold {
		font-weight: bold;
	}
	.text-blue {
		@include font-size(16);
		font-weight: bold;
		color: #35436e;
	}

	.blc-contents-outline {
		margin-top: 30px;
		.blc-table {
			font-weight: bold;
			@include font-size(18);
			@media screen and (max-width:767px) {
				@include font-size(14);
			}
			caption {
				display: none;
			}
			.blc-thead {
				background-color: #ff8c46;
				th {
					padding: 25px 0;
				}

			}
			.blc-tbody {
				tr {
					th,td {
						padding: 15px;
					}
				}
				td {
					color: #ff0000;
				}
			}
			.bg-icon {
				background: url('../img/ico_coin.png') no-repeat;
				background-position: left 10px center;
				@media screen and (max-width:767px) {
					background-size: 15%;
				}
			}
		}
		.blc-outline {
			background-color: #f9f9f9;
			padding: 10px 25px 30px 25px;
			margin-bottom: 30px;
			border-top:solid 3px #ff8c46;
			border-bottom:solid 3px #ff8c46;
			position: relative;
			@media screen and (max-width:767px) {
				padding: 10px 15px 30px 15px;
			}

		}
		.blc-outline:before {
			position: absolute;
			display: block;
			content:"";
			background: url('../img/bg_ribbon_orange.gif') no-repeat;
			width: 54px;
			height: 82px;
			top: -10px;
			@media screen and (max-width:767px) {
				background-size: 65%;
			}
		}
		.blc-contact {
			border-top:solid 3px #6eaaff;
			border-bottom:solid 3px #6eaaff;
			@media screen and (max-width:767px) {
				h3 {
					@include font-size(20);
				}
			}
			.blc-form {
				padding: 8px;
			}
		}
		.blc-contact::before {
			background: url('../img/bg_ribbon_blue.gif') no-repeat;
			@media screen and (max-width:767px) {
				background-size: 65%;
			}
		}
		.blc-point {
			@media screen and (max-width:767px) {
				h3 {
					@include font-size(20);
				}
			}
		}
		.blc-point-step {
			margin: 50px 0;
			dd {
				margin-top: 15px;
				@include font-size(16);
			}
		}
		.blc-point-step-dl {
			border-color: #ff8c46;
			margin-bottom: 30px;
			dd {
				span:nth-of-type(2) {
					color: #ff8c46;
				}
			}
			.blc-point-read {
				padding: 10px 10px 10px 60px;
				background:-webkit-linear-gradient(#faaa46 60%, #ff8c46 40%, #ff8c46);
				background:-o-linear-gradient(#faaa46 60%, #ff8c46 40%, #ff8c46);
				background:linear-gradient(#faaa46 60%, #ff8c46 40%, #ff8c46);
				position: relative;
				@include font-size(16);
			}
			.blc-point-read::before {
				position: absolute;
				display: block;
				content:"";
				background: url('../img/ico_point1_s.png') no-repeat;
				width: 68px;
				height: 74px;
				top: -20px;
				left: -20px;
			}
		}
		.blc-point-step-dl:nth-of-type(2){
			.blc-point-read:before {
				background: url('../img/ico_point2_s.png') no-repeat;
			}
		}
		.blc-point-step-dl:nth-of-type(3){
			.blc-point-read:before {
				background: url('../img/ico_point3_s.png') no-repeat;
			}
		}
	}
}
.pg-full{
	.blc-logo{
		padding: 20px 0 40px;
	}
	h3{
		padding: 30px;
	}
}
.blc-entry {
	margin-top: 40px;
	button {
		padding: 10px 60px;
	}
}


.blc-sidemenu {
	.blc-info-header {
		background-color: #35436e;
		padding: 0 10px;
		.text-h3-info {
			margin-top: 15px;
			font-weight: bold;
			@include font-size(18);
			color: #FFF;
		}
		button {
			background-color: #FFF;
		}
	}
	.blc-company {
		ul {
			li:before {
				content: "";
				display: inline-block;
				background: url('../img/common/ico.square.gif') no-repeat;
				width: 22px;
				height: 14px;
			}
			a {
				color: #35436e;
			}
		}
	}
}

.footer {
	width: 100%;
	background-color: #333333;
	color: #FFF;
	margin-top: 30px;
	.blc-footer-inline {
		padding: 40px 0 20px 0;
		.blc-footer-menu {
			padding: 0 50px;
		}
		.blc-footer-logo {
			padding: 40px 0 20px 0;
		}
		.blc-footer-ul {
			padding-bottom: 35px;
			border-bottom: dotted 1px #FFF;
			li:before {
				content: "｜";
				display: inline-block;
			}
			li:first-child:before {
				display: none;
			}
			a {
				padding-left: 10px;
				color: #FFF !important;
			}
		}
	}
}

.pg-flow {
	.blc-contents-outline {
		.blc-point {
			h2 {
				@include font-size(24);
				@media screen and (max-width:767px) {
					@include font-size(16);
				}
			}
		}
		.blc-plan {
			background: url('../img/bg_title.png') repeat-x;
			margin: 50px 0;
			height: 40px;
			.title-plan-day {
				vertical-align: top;
			    padding-top: 7px;
			    position: relative;
			   	@media screen and (max-width:767px) {
					padding-top: 5px;
				}
			    span {
			    	padding-left:70px;
			    	@media screen and (max-width:767px) {
						@include font-size(16);
						padding-left:60px;
					}
			    }
			}
			.title-plan-day:before {
				position: absolute;
				content: "";
				display: inline-block;
				background: url('../img/ico_meter.png') no-repeat;
				width: 48px;
				height: 36px;
			    top: -4px;
			    left: 5px;
			}
		}
		.blc-list-bank {
			padding: 30px 0 50px 0;
			margin-bottom: 0;
			li {
				margin-top: 20px;
				img {
					margin: 0 auto;
				}
			}
		}
		.blc-bank-note {
			span {
				color: #ff8c46;
			}
			.text-nomal-blue {
				color: #35436e;
			}
		}
		.blc-step-list-ul {
			margin-top: 50px;
			margin-bottom: 50px;
			li {
				margin-bottom: 15px;
			}
		}

		.blc-point-step-dl {
			.blc-point-read {
				margin-top: 0;
				padding: 10px 10px 10px 85px
			}
			.blc-point-read:before {
				position: absolute;
				display: block;
				content:"";
				background: url('../img/ico_step1.png') no-repeat;
				width: 74px;
				height: 32px;
				top: 3px;
				left: 0;
			}
		}
		.blc-point-step-dl:nth-of-type(3) {
			.blc-point-read:before {
				background: url('../img/ico_step1.png') no-repeat;
				width: 74px;
			}
		}
		.blc-point-step-dl:nth-of-type(4) {
			.blc-point-read:before {
				background: url('../img/ico_step2.png') no-repeat;
				width: 77px;
			}
		}
		.blc-point-step-dl:nth-of-type(5) {
			.blc-point-read:before {
				background: url('../img/ico_step3.png') no-repeat;
				width: 77px;
			}
		}
		.blc-point-step-dl:nth-of-type(6) {
			.blc-point-read:before {
				background: url('../img/ico_step4.png') no-repeat;
				width: 77px;
			}
		}
		.blc-entry-read {
			margin: 40px 0;
			p {
				@include font-size(22);
			}
		}
	}
}
.pg-guide {
	.section {
		margin-bottom: 2em;
		h2 {
			@include font-size(18);
			font-weight: bold;
		}
	}
}
.pg-company .blc-contents-outline .blc-table .blc-tbody td {
	color: #000;
	font-weight: normal;
}
.pg-top {
	main {
		position: relative;
		.rate-info {
			position: absolute;
			left: 3%;
			top: 30%;
			line-height: 100%;
			.rate {
				color: red;
				font-weight: bold;
				text-shadow: 1px 1px 3px rgba(0,0,0,0.5);
				line-height: 100%;
				vertical-align: bottom;
				font-size: 20vw;
				letter-spacing: -1.5vw;
			}
		}
	}
}
.modal-body {
	dl {
		display: block;
	}
	dt, dd {
		display: inline-block;
		min-width: 10em;
	}
}
@media (max-width: 640px) {
	.pg-item {
		.col-xs-4, .col-xs-3 {
			width: 100%;
		}
	}
	.modal-body-giftitem dt {
		width: 100%;
	}
	.modal-body-giftitem dd {
		width: 100%;
	}
}
@media (min-width: 768px) {
	.pg-top main {
		.rate-info {
			left: 2%;
			top: 32%;
			.rate {
				font-size: 168px;
				letter-spacing: -12px;
			}
		}
	}
	.blc-header-top .blc-logo,
	.blc-header-top .blc-tel {
		text-align: center;
	}
}
@media (min-width: 992px) {
	.pg-top main {
			.rate-info {
				left: 4%;
				top: 36%;
				.rate {
					font-size: 198px;
					letter-spacing: -12px;
			}
		}
	}
	.blc-header-top .blc-logo,
	.blc-header-top .blc-tel {
		text-align: left;
	}
}
@media (min-width: 1200px) {
	.pg-top main {
		.rate-info {
			left: 4%;
			top: 39%;
			.rate {
				font-size: 221px;
				letter-spacing: -12px;
			}
		}
	}
	.blc-header-top .blc-logo,
	.blc-header-top .blc-tel {
		text-align: left;
	}
}

/* module
---------- */

.blc-faq .list-group-item-heading,
.blc-faq .list-group-item-text {
	padding-left: 1em;
	text-indent: -1em;
}
.blc-message h1 {
	border-bottom: solid 1px $brand-primary;
	margin-bottom: 36px;
}

.blc-form {
	.form-group {
		label,span {
			line-height: 34px;
			margin: 0;
			display: inline-block;
		}
		.blc-input {
			display: inline-block;
			.form-control {
				width: 87%;
			}
		}
		.blc-label:before {
			content: "　　";
			display: inline-block;
			padding: 5px;
			margin-right: 20px;
			color: #FFF;
		}
		&.required .blc-label:before {
			content: "必須";
			display: inline-block;
			padding: 5px;
			margin-right: 20px;
			color: #FFF;
			background-color: #d9534f;
			border-radius: 2px;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
		}
		.blc-input-holder {
			display: inline-block;
		}
		.blc-textarea {
			vertical-align: top;
		}
	}
}
.user-message {
	padding: 16px;
}
.btn-news-link{
	background: #FFF;
	color:#333;
}
.m-t{
	margin-top: 3em;
	label{
		line-height: 34px;
		margin: 0;
	}
}
.mt-0{
	margin-top: 0;
}